// TODO: 接口域名配置
const preApi = process.env.VUE_APP_HOST || "";

let baseUrls = {
  production: "https://shopper-api.haomianlai.com",
  development: "",
};
//根据环境，打包阶段产出不同环境的baseUrl
let NODE_ENV = process.env.NODE_ENV;

const baseUrl = baseUrls[NODE_ENV];

export default baseUrl + preApi;
