import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import baseUrl from "./baseUrl.js";
import utils from "../../utils";
import { Toast } from "@nutui/nutui";

const secret = "ceae37f3ea9da37932dd78469c83630c";

let token = "";
const emptyPromise = new Promise(() => {});
/**
 * 获取系统参数
 */
const getSysParams = () => {
  let di = utils.getDi();
  return {
    ct: "wx", //pc
    ts: Math.ceil(Date.now() / 1000),
    di, //非 App 端由 uni-app 框架生成并存储，清空 Storage 会导致改变
    vn: "1.0",
  };
};

/**
 * 生成签名
 * @sysParams 系统参数
 * @businessParams 业务参数
 */
const getSign = (sysParams = {}, businessParams = {}) => {
  let params = {
    ...sysParams,
    ...businessParams,
  };
  let keys = Object.keys(params).sort();
  let str = keys.reduce((prev, next) => {
    return `${prev}${next}${params[next]}`;
  }, "");
  let signStr = `${str}${secret}`;
  return md5(signStr);
};

/**
 * 根据请求，处理请求参数
 */
const formatPayload = (config) => {
  let method = config.method.toLowerCase();
  let data = config.data;
  let sysParams = getSysParams();
  let sg;
  if (method === "get") {
    sg = getSign(sysParams, data);
    config.data = {
      sg,
      ...sysParams,
      ...data,
    };
  } else {
    //手动处理post请求时的query参数
    sg = getSign(sysParams, data);
    let query = {
      sg,
      ...sysParams,
    };
    config.data =`${qs.stringify(data)}`;
    config.url += `?${qs.stringify(query)}`;
  }
  return config;
};

let config = {
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const _axios = axios.create(config);
let isJumpLogin = true;
// 请求拦截
_axios.interceptors.request.use(
  (config) => {
    // 可使用async await 做异步操作
    // 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
    config.data = config.data || {};

    token = utils.getToken();
    if (token) {
      config.data.token = token;
    }
    if(config.data.is_jump_login != undefined &&config.data.is_jump_login  === false){
      isJumpLogin = false;
    }else{
      isJumpLogin = true;
    }

    //content-type 区分，post请求 进行区分； get请求默认application/json
    if (config.method.toLowerCase() == "post") {
      config.headers["content-type"] =
        "application/x-www-form-urlencoded;charset=UTF-8";
    }

    //处理请求参数
    config = formatPayload(config);

    return config;
  },
  (config) => {
    // 可使用async await 做异步操作
    return Promise.reject(config);
  }
);

// 响应拦截
_axios.interceptors.response.use(
  (response) => {
    /* 对响应成功做点什么 可使用async await 做异步操作*/
    const data = response.data;
    if (data.code === 1600005) {
      Toast.fail(data.message);
      return emptyPromise;
    }
    //重新登录
    if (data.code === 1600001 && isJumpLogin == true) {
      // 登录失效 todo 跳转到去登陆
      window.location.href ="/login"
      return emptyPromise;
    }
    data.data = data.data === undefined ? {} : data.data;
    return data;
  },
  (response) => {
    // 对响应错误做点什么 （statusCode !== 200）
    return Promise.reject(response);
  }
);

export default _axios;
